import delegateEvent from '../init/delegate-event'
import init from '../init';

function openModal (modalElement) {
  document.body.classList.add('show-modal')
  closeOpenModals()
  modalElement.classList.add('show-modal')
}

function openModalSelector(modalSelector) {
  const el = document.querySelector(modalSelector)
  if (el && el.matches('.modal')) openModal(el)
}

function closeModal (modalElement) {
  document.body.classList.remove('show-modal')
  closeOpenModals()
}

function modalBackgroundClick (e) {
  // This depends on the .modal taking up the whole screen
  // and being the thing that is e.target. Would be preferable
  // for it to be clicking outside the modal, but with the modal
  // always filling the whole screen, it's hard to do that.
  if (e.target.matches('.modal.show-modal')) {
    e.preventDefault()
    closeModal(e.target)
  }
}

function closeOpenModals () {
  const openModals = document.body.querySelectorAll('.show-modal')
  openModals.forEach((modal) => { modal.classList.remove('show-modal') })
}

function modalPop (event) {
  event.preventDefault()
  let modalSelector = event.target.closest('[modal-href]')?.getAttribute('modal-href')
  modalSelector = modalSelector || event.target.closest('[href]')?.getAttribute('href')
  if (!modalSelector) return

  openModalSelector(modalSelector)
}

function linkCloseModal (e) {
  e.preventDefault()
  const modal = e.target.closest('.modal')
  closeModal(modal)
}

function escapeCloseModal (e) {
  if (e.key === 'Escape') {
    if (document.body.classList.contains('show-modal')) {
      closeModal()
    }
  }
}

delegateEvent('click', 'body.show-modal .modal.show-modal', modalBackgroundClick)
delegateEvent("click", ".modal-pop", modalPop)
delegateEvent('click', '.modal.show-modal .close', linkCloseModal)
delegateEvent('click', '.modal.show-modal .text-close', linkCloseModal)
delegateEvent('click', '.turbo-modal-pop', modalPop)

document.addEventListener('keydown', escapeCloseModal)

// TODO: Remove legacy Free Modal
function closeModalEl (e) {
  e.preventDefault();
  $('body, .modal').removeClass('show-modal');
  $('body').removeClass(function(index, css) {
    return (css.match(/(^|\s)open-\S+/g) || []).join(' ');
  });
}
$(document).on('click', '.modal-overlay .close, .modal-overlay', closeModalEl);

export {
  openModal,
  openModalSelector,
  closeModal
}
