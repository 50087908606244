export const defaultFilters = {
  // Main Search Bar
  destination_id: null,
  word_search: "",
  start_date: "",
  end_date: "",
  min_beds: 1,

  // Search Type
  search_type: 'properties',

  // Stay Options
  affiliate: false,
  open_availability: false,
  extended_stays: false,
  short_stays: false,
  pet_friendly: false,
  full_service: false,
  requestable: false,
  handicap_accessible: false,
  pool: false,
  beach: false,

  // Availibility Keys
  min_keys: 1,
  max_keys: 15,
  keyless: false,
  marked_down: false,

  // Property Type
  boutique_hotel: false,
  estate: false,
  private_residence: false,
  reserve: false,
  ypo: false,
  yacht: false,

  // Activities
  arts: false,
  equestrian: false,
  fishing: false,
  golf: false,
  hiking: false,
  tennis: false,
  shopping: false,
  skiing: false,
  watersports: false,
  wine_tasting: false
}

export const filterNames = Object.keys(defaultFilters)

export const filterCopy = {
  affiliate: "Affiliate Resorts",
  open_availability: "",
  extended_stays: "",
  short_stays: "",
  pet_friendly: "Pet Friendly" ,
  full_service: "",
  requestable: "Accepts Week Requests" ,
  handicap_accessible: "Handicap Accessible" ,

  // Availibility Keys
  min_keys: "Minimum keys" ,
  max_keys:  "Maximum keys",
  keyless: "Available Keyless",
  marked_down: "On Sale",

  // Property Type
  boutique_hotel: "Boutique Hotels",
  estate: "Estate",
  private_residence: "Private Residence",
  reserve: "Reserve Tier",
  ypo: "Y Club",
  yacht: "Yacht",

  // Activities
  arts: "Arts",
  equestrian: "Equine",
  fishing: "Fishing",
  pool: "Pool" ,
  beach: "Beach" ,
  golf: "Golfing",
  hiking: "Hiking",
  tennis: "Tennis",
  shopping: "Shopping",
  skiing: "Skiing",
  watersports: "",
  wine_tasting: "Winery"
}