<template lang='pug'>
  .vue-template-wrapper
    .row-error
    .grid.confirm-deposits-row.edge
      .cell.twelve.medium-four.order-2.medium-order-1
        button(class='button full' @click='addDeposit' :disabled='!optionSelected' data-title="Add weeks" data-intro="Click and select another start date or continue and confirm added weeks." data-step="4") Add and Continue
      .cell.twelve.medium-eight.order-1.medium-order-2(data-title="See how many keys the week you are adding is worth" data-intro="Key values are based on seasonality and color coded on your calendar" data-step="3" )
        DepositOptions(:selectedDate='selectedDate'
                       :selectedDeposits='selectedDeposits'
                       :optionUrl='optionUrl'
                       @stayPicked='setStay'
                       @error='handleError')
      .cell.twelve.medium-four.hide.order-3
        DateInput(:selectedDate='selectedDate', @dateSelected='dateSelected')
      .cell.twelve.medium-eight.order-4
        p.error-message {{errorMessage}}
</template>

<script>
import DateInput from './DateInput.vue';
import DepositOptions from './DepositOptions.vue';
import Ranger from '../Ranger.js';

export default {
  components: { DateInput, DepositOptions },
  props: {
    optionUrl: { type: String, required: true },
    selectedDeposits: { required: true },
    selectedDate: { type: String },
    splitWeeks: { type: Boolean, required: true }
  },
  data () { return { errorMessage: '', selectedStay: {} } },
  watch: { selectedDate (newDate, oldDate) { this.errorMessage = ''; } },
  computed: {
    optionSelected () {
      return !!(this.selectedStay && this.selectedStay.identifier)
    },
    minStayLength () {
      return this.splitWeeks ? 3 : 7
    }
  },
  methods: {
    handleError (error) {
      this.errorMessage = error; 
      this.$emit("stayPicked", {
        valid: false,
        range: Ranger.range(this.selectedDate, dayjs(this.selectedDate).add(this.minStayLength, 'd')),
      });
    },
    dateSelected (date) { this.$emit('dateSelected', date); },
    setStay(deposit) {
      this.selectedStay = deposit;
      if (deposit == "") return;
      this.$emit("stayPicked", {
        valid: true,
        range: Ranger.range(deposit.starts_on, deposit.ends_on),
      });
    },
    depositAllowed () {
      if(this.selectedDeposits.length === 0) { return true; }

      return Ranger.datesOverlapRanges(
        this.selectedStay.starts_on,
        this.selectedStay.ends_on,
        this.selectedDeposits
      );
    },
    addDeposit () {
      if(!this.selectedDate) {
        this.errorMessage = 'You must select a deposit date';
        return;
      } else if (!this.optionSelected) {
        this.errorMessage = 'You must select from the available options';
        return;
      } else if (!this.depositAllowed()) {
        this.errorMessage = 'This deposit conflicts with other selected stays';
        return;
      } else {
        this.errorMessage = '';
      }

      this.$emit('addDeposit', this.selectedStay);

      const event = new Event("proceed-introjs-tour", {bubbles: true});
      this.$el.dispatchEvent(event);
    }
  }
}
</script>
