var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "deposit" }, [
    _c("div", { staticClass: "grid align-middle edge" }, [
      _c(
        "div",
        {
          staticClass: "cell date-range twelve",
          class: { shrink: _vm.requireConfirmationIds },
          staticStyle: { "margin-bottom": ".25rem" }
        },
        [
          _c("span", { staticClass: "starts-on date" }, [
            _vm._v(_vm._s(_vm.formattedRange.formattedStart))
          ]),
          _vm._v("\nto\n"),
          _c("span", { staticClass: "ends-on date" }, [
            _vm._v(_vm._s(_vm.formattedRange.formattedEnd))
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.deposit.identifier,
                expression: "deposit.identifier"
              }
            ],
            attrs: { type: "hidden", name: "dates[]" },
            domProps: { value: _vm.deposit.identifier },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.deposit, "identifier", $event.target.value)
              }
            }
          })
        ]
      ),
      _c("div", { staticClass: "cell key-count-column fill" }, [
        _vm.hasKeyPriceOverride()
          ? _c("span", [_vm._v(_vm._s(_vm.key_price_override))])
          : _c("span", [_vm._v(_vm._s(_vm.deposit.keys))]),
        _vm._v(" "),
        _c("span", { staticClass: "third-font-key-glyph" }),
        _vm.showTipso
          ? _c(
              "span",
              {
                staticClass: "tipso_styles asterisk",
                attrs: {
                  "data-tipso":
                    "* Keys for this week will be awarded when it is reserved by a guest. This week will not go keyless."
                }
              },
              [_vm._v("✱")]
            )
          : _vm._e()
      ]),
      _c(
        "div",
        {
          staticClass: "cell fit actions text-right",
          class: {
            "small-shrink medium-12": _vm.requireConfirmationIds,
            shrink: !_vm.requireConfirmationIds
          }
        },
        [
          _c(
            "a",
            {
              staticClass: "small remove-booking underline",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("removeDeposit", _vm.id)
                }
              }
            },
            [_vm._v("remove")]
          )
        ]
      ),
      _vm.requireConfirmationIds
        ? _c("div", { staticClass: "cell twelve resort-confirmation" }, [
            _c(
              "div",
              { staticClass: "input", staticStyle: { "margin-top": ".75rem" } },
              [
                _vm._m(0),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.deposit.resort_confirmation_id,
                      expression: "deposit.resort_confirmation_id",
                      modifiers: { trim: true }
                    }
                  ],
                  attrs: { type: "text", name: _vm.formatResortIdInputName() },
                  domProps: { value: _vm.deposit.resort_confirmation_id },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.deposit,
                        "resort_confirmation_id",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      return _vm.$forceUpdate()
                    }
                  }
                })
              ]
            )
          ])
        : _vm._e()
    ]),
    _vm.canNamePrice
      ? _c("div", { staticClass: "grid align-top edge name-your-price" }, [
          _c("div", { staticClass: "cell twelve" }, [
            _c(
              "div",
              {
                staticClass: "input small check_boxes",
                staticStyle: { "margin-bottom": "0" }
              },
              [
                _c("span", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.nameYourPrice,
                        expression: "nameYourPrice"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: _vm.deposit.identifier,
                      name: _vm.deposit.identifier,
                      value: "1"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.nameYourPrice)
                        ? _vm._i(_vm.nameYourPrice, "1") > -1
                        : _vm.nameYourPrice
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.nameYourPrice,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "1",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.nameYourPrice = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.nameYourPrice = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.nameYourPrice = $$c
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticStyle: {
                        "padding-left": "0",
                        "margin-left": "8px"
                      },
                      attrs: {
                        for: _vm.deposit.identifier,
                        "data-tipso": _vm.nameYourPriceTooltip
                      }
                    },
                    [_vm._v("Name Your Price")]
                  )
                ])
              ]
            )
          ]),
          _vm.nameYourPrice
            ? _c("div", { staticClass: "cell fit" }, [
                _c("label", [_vm._v("New Price:")])
              ])
            : _vm._e(),
          _vm.nameYourPrice
            ? _c("div", { staticClass: "cell fill" }, [
                _c("div", { staticClass: "input small" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.key_price_override,
                          expression: "key_price_override"
                        }
                      ],
                      staticClass: "select-box",
                      staticStyle: { "margin-bottom": "0" },
                      attrs: { name: _vm.formatKeyPriceOverrides() },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.key_price_override = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.keyOptions, function(index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: index } },
                        [_vm._v(_vm._s(index))]
                      )
                    }),
                    0
                  )
                ]),
                _c("br")
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v(" Resort Reservation Confirmation Number* "),
      _c(
        "span",
        {
          staticClass: "info",
          attrs: {
            "data-tipso":
              "Provided by your resort when you reserved the deposited week."
          }
        },
        [_vm._v("i")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }