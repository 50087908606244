var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "deposit-entry", class: _vm.deposit.season },
    [
      _c("div", { staticClass: "grid align-center collapse-padding" }, [
        _c("div", { staticClass: "cell date-range fill" }, [
          _c("span", { staticClass: "third-font-calendar" }),
          _c("span", { staticClass: "starts-on date" }, [
            _vm._v(_vm._s(_vm.formattedRange.formattedStart))
          ]),
          _vm._v("\nto\n"),
          _c("span", { staticClass: "ends-on date" }, [
            _vm._v(_vm._s(_vm.formattedRange.formattedEnd))
          ])
        ]),
        _c("div", { staticClass: "cell fit" }, [
          _c("div", { staticClass: "pill tiny season" }, [
            _vm._v(_vm._s(_vm.deposit.season.replace(/_/g, " ")))
          ])
        ]),
        _c("div", { staticClass: "cell key-count-column two text-right" }, [
          _c(
            "span",
            { staticClass: "key-count", attrs: { title: _vm.keyCountTitle } },
            [
              _c("span", { staticClass: "count" }, [
                _vm._v(_vm._s(_vm.deposit.keys))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "third-font-key-glyph" })
            ]
          ),
          _vm.showTipso
            ? _c(
                "span",
                {
                  staticClass: "tipso_styles asterisk",
                  attrs: {
                    "data-tipso":
                      "* Keys for this week will be awarded when it is reserved by a guest. This week will not go keyless."
                  }
                },
                [_vm._v("✱")]
              )
            : _vm._e()
        ]),
        _vm.showTipso
          ? _c("div", { staticClass: "cell twelve" }, [
              _c(
                "p",
                {
                  staticClass: "no-bottom-margin small strong red-text",
                  staticStyle: { "line-height": "1.1", "margin-top": ".25rem" }
                },
                [
                  _vm._v(
                    "Keys for this week will be awarded once reserved by a guest."
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }