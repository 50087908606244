<template lang='pug'>
.deposit-entry(:class='deposit.season')
  .grid.align-middle.edge
    .cell.date-range.twelve(style="margin-bottom:.25rem")
      span.starts-on.date {{formattedRange.formattedStart}}
      |
      | to
      |
      span.ends-on.date {{formattedRange.formattedEnd}}
    .cell.key-count-column.fit
      span.key-count(:title='keyCountTitle')
        span.count(v-if="hasKeyPriceOverride()")
          | {{key_price_override}}
        span.count(v-else)
          | {{deposit.keys}}
        | &nbsp;
        span.third-font-key-glyph
      span.tipso_styles.asterisk(v-if='showTipso',
            data-tipso='* Keys for this week will be awarded when it is reserved by a guest. This week will not go keyless.')
        | &#10033;
    .cell.season.fill
    .cell.fit.text-right
      small
        a(@click="$emit('removeDeposit', deposit.identifier)", class="underline") Remove
    .cell.twelve.resort-confirmation(v-if="requireConfirmationIds")
      .input(style="margin-top:.75rem")
        label Resort Reservation Confirmation Number*
        input(type='text', :name='formatResortIdInputName()' v-model.trim='deposit.resort_confirmation_id' style="margin-bottom:.25rem")
        
  .grid.align-center.edge.name-your-price.name-your-price--flexible(v-if="canNamePrice")
    .cell.twelve
      .input.small.check_boxes(style="margin-bottom:0")
        span.checkbox
          input(type="checkbox"
                :id="deposit.identifier"
                :name="deposit.identifier"
                v-model="nameYourPrice" value="1")
          label(:for="deposit.identifier", :data-tipso="nameYourPriceTooltip" style="padding-left:0;margin-left:8px") Name Your Price
    .cell.fit(v-if="nameYourPrice")
      label(:for="formatKeyPriceOverrides()") New Price:
    .cell.fill(v-if="nameYourPrice")
      .input.small
        select(:name="formatKeyPriceOverrides()" v-model="key_price_override" :id="formatKeyPriceOverrides()" style="margin-bottom:0")
          option(v-for="index in keyOptions" :key="index" :value="index")
            | {{index}}
  input(type='hidden', name='dates[]', v-model='deposit.identifier')
</template>

<script>
export default {
  props: {
    deposit: { type: Object, required: true },
    requireConfirmationIds: { type: Boolean, required: true },
    ownerCanNyop: { type: Boolean, required: false }
  },
  data () {
    const mStartDate = dayjs(this.deposit.starts_on)
    return {
      nameYourPrice: false,
      ends_on: mStartDate.add(this.deposit.stayLength, 'days').format('YYYY-MM-DD'),
      key_price_override: this.deposit.keys.toString()
    }
  },
  methods: {
    formatResortIdInputName () {
      return `confirmation_ids[${this.deposit.identifier}]`
    },
    formatKeyPriceOverrides () {
      return `key_price_overrides[${this.deposit.identifier}]`
    },
    hasKeyPriceOverride () {
      return this.nameYourPrice && this.key_price_override;
    }
  },
  computed: {
    canNamePrice () {
      if (!this.ownerCanNyop) return false 
      return ['peak', 'super_peak', 'non_peak', 'off_season'].includes(this.deposit.season)
    },
    showTipso () {
      return this.deposit.delayed_keys || this.nameYourPrice
    },
    nameYourPriceTooltip () {
      return "You can now choose to receive up to 50% more Keys for this stay.  However, if you elect to do so, you will only get the Keys when the stay is booked and it will not go Keyless."
    },
    keyCountTitle () {
      return `${this.deposit.keys} Keys`
    },
    keyOptions () {
      let arry = []
      for (let i=this.deposit.keys; i <= this.maxKeys; i++) {
        arry.push(i)
      }
      return arry
    },
    maxKeys () {
      return Math.ceil(this.deposit.keys * 1.5)
    },
    formattedRange () {
      return formattedStayRange(this.deposit.starts_on, this.deposit.ends_on)
    }
  }
}
</script>
