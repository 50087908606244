var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "browse-homes", class: { "logged-in": _vm.loggedIn } },
    [
      _vm.stateLoaded
        ? _c("div", { attrs: { id: "property-search" } }, [
            _c("div", { staticClass: "small-container search-container" }, [
              _c(
                "div",
                {
                  attrs: {
                    "data-step": "1",
                    "data-position": "top",
                    "data-title": "Choose your destination",
                    "data-intro":
                      "Type in a place you want to go. <em>Example: Europe.</em>"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tail-background",
                      attrs: {
                        "data-step": "2",
                        "data-tooltip-class": "available-tip",
                        "data-position": "top",
                        "data-title": "Choose your timeframe",
                        "data-intro":
                          "Add a date range (the broader the better). <em>HINT: The more flexible you are, the better the club will work for you.</em>"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "base-form search-bar medium-show" },
                        [
                          _c(
                            "div",
                            { staticClass: "form grid collapse-padding" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "cell twelve medium-fill destination-container"
                                },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "destination-label",
                                      class: {
                                        selected: _vm.destinationSelected
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.destinationSelectionText)
                                      )
                                    ]
                                  ),
                                  _c("BrowseSearch", {
                                    attrs: {
                                      "zones-url": _vm.zonesUrl,
                                      "property-url": _vm.propertyUrl
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.canViewAvailability
                                ? _c("StayDuration", {
                                    attrs: { parent: "main" }
                                  })
                                : _vm._e(),
                              _c("BedroomCount"),
                              _vm._m(0)
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "large-container", attrs: { id: "combined-results" } },
        [
          _c("SearchResults", {
            attrs: {
              zonesUrl: _vm.zonesUrl,
              introJsEnabled: _vm.introJsEnabled,
              savedSearches: _vm.savedSearches,
              ypoUser: _vm.ypoUser,
              ypoOnly: _vm.ypoOnly,
              promoFilterFeatureActive: _vm.promoFilterFeatureActive,
              promotionUrl: _vm.promotionUrl,
              filterPillsTestEnabled: _vm.filterPillsTestEnabled
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cell fit submit-container medium-show" }, [
      _c("button", { staticClass: "button" }, [
        _c("span", { staticClass: "third-font-search-glyph" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }