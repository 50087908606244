var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "end-select", class: _vm.deposit.season }, [
    _c("label", { staticClass: "single-checkbox-container grid" }, [
      _c("div", { staticClass: "cell checkbox-column fit" }, [
        _c("input", {
          attrs: { type: "radio", name: "depositLengthSelection" },
          on: {
            change: function($event) {
              return _vm.$emit("stayPicked", _vm.deposit)
            }
          }
        }),
        _c("span", { staticClass: "single-checkbox" })
      ]),
      _c("div", { staticClass: "cell date" }, [
        _c("span", { staticClass: "ends-on date" }, [
          _vm._v(_vm._s(_vm.endsOn))
        ]),
        _c("div", [
          _c("span", { staticClass: "third-font-calendar" }),
          _vm._v(" " + _vm._s(_vm.deposit.length) + " Nights\n  /  "),
          _c(
            "span",
            { staticClass: "key-count", attrs: { title: _vm.keyCountTitle } },
            [
              _c("strong", { staticClass: "count" }, [
                _vm._v(_vm._s(_vm.deposit.keys))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "third-font-key" })
            ]
          ),
          _vm.showTipso
            ? _c(
                "span",
                {
                  staticClass: "tipso_styles asterisk",
                  attrs: {
                    "data-tipso":
                      "* Keys for this week will be awarded when it is reserved by a guest. This week will not go keyless."
                  }
                },
                [_vm._v("✱")]
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }