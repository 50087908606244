import ScriptLoader from '../tools/script-loader';

const mapboxPublicKey = process.env.MAPBOX_KEY;

const loader = new ScriptLoader(
  'https://api.mapbox.com/mapbox-gl-js/v2.14.1/mapbox-gl.js',
  function () {
    const mapboxgl = window.mapboxgl;
    if (mapboxgl && !mapboxgl.accessToken) {
      mapboxgl.accessToken = mapboxPublicKey;
    }
    return mapboxgl;
  }
);

export default loader;
